import React from "react";
import LazyLoad from "react-lazyload";
import placeholder from "../img/featured-image-placeholder.svg";

const ImgLazy = props => (
  <LazyLoad once height={props.height || 500}>
    <img
      src={props.src ? props.src : placeholder}
      className={props.className}
      style={props.style}
      width={props.width}
      height={props.height}
      alt={props.alt ? props.alt : ""}
    />
  </LazyLoad>
);

export default ImgLazy;
