import React from "react";
import ImgLazy from "../../components/ImgLazy";
import Layout from "../../components/Layout/Layout";
import iconCite from "../../img/icon-quote.png";
import SEO from "../../components/Seo";
import { Box, Button, Container, Image, Lead, T2 } from "../../styles/Styles";
import hero from "../../img/hero-energy-ind.png";
import Clients from "../../components/Homepage/Clients";
import Mikan from "mikanjs";
const benefits = [
  "資産活用のためのリアルタイムのデータ",
  "雷の脅威予測とアラート",
  "分刻みで最新の降水量と火災指数",
  "特定資産における気象インサイト"
];

export default () => {
  return (
    <Layout>
      <SEO
        title="エネルギー | tomorrow.io"
        image={hero}
      />

      <Container>
        <Box display="flex" flexWrap="wrap" pb={4}>
          <Box
            width={[1, 1 / 2]}
            pr={[0, 4]}
            pt={5}
          >
            <T2 as="h1" mb={3}>
              テーラードされた気象予報。パワフルな予測。
            </T2>
            <Lead mb={4}>
              高解像度と、実用的な情報を手に入れよう。特定の気象予報を把握して行動し、その後の状況に対処。
            </Lead>
            <Button
              as="a"
              display={["block", "inline-block"]}
              mb={2}
              href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
            >
              tomorrow.ioデモ取得
            </Button>{" "}
            <Button
              outline="1"
              as="a"
              display={["block", "inline-block"]}
              mb={2}
              href="https://www.tomorrow.io/api-signup/"
            >
              APIキー取得
            </Button>
          </Box>
          <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
            <Image
              maxHeight="380px"
              src={hero}
              alt=""
            />
          </Box>
        </Box>
      </Container>

      <section className="c-section py-5">
        <div className="container">
          <Clients/>
        </div>
      </section>

      <section className="c-section">
        <h3 className="h-3 text-center">ベネフィット：</h3>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="row justify-content-center">
                {benefits.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-lg-3 mt-5 pr-0 pr-xl-3"
                  >
                    <div className="item-bullet"  dangerouslySetInnerHTML={{__html: Mikan(item)}}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="c-section pb-0">
        <div className="container">
          <div className="row justify-content-center text-dark">
            <div className="col-lg-3">
              <h5 className="fw-700 lh-md">
                私たちには他が予測できない、天候の動きが把握できます。公益事業のマネージャーは、デジタルで繋がっている世界から得る、新しい気象予報を活用し、発電、送電、配電を最適化できます。
              </h5>
              <div className="divider-blue my-5 w-25"/>
            </div>
            <div className="col-lg-6">
              <div className="lead fs-lg mb-5 pl-lg-4">
                <p>tomorrow.ioは、複数の繋がっているネットワークレイヤを利用して気象予報データを抽出し、想像を超える膨大な情報源による世界の気象をマッピングします。</p>

                <p>グリッドの最適化ならびに自動化の為に、MicroWeatherデータを利用して、公益事業の tomorrow.io™ ダッシュボードのインフラをモニターします。tomorrow.ioの道路レベルの予報は、厳しい気象状況や嵐からの回復時に、設備の配置や作業員に危険が及ばないよう配慮をし、管理者の自主的な行動を導くことをサポートします。</p>

                <p>都市部の各地域で、何が起こるのかを推測する代わりに、tomorrow.ioは鮮明で、詳細な気象予報を道路レベルの解像度で表示します。私たちは、レーダーの倍の信頼がおける、分単位の短期間予測を提示します。</p>

                <p>tomorrow.ioは、降水量の分類や、危険な気象現象が起こるずいぶん前にアラートを発信します。</p>

                <Button
                  as="a"
                  display={["block", "inline-block"]}
                  mb={2}
                  id="industry-energy-link-to-form-2"
                  href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                >
                  デモを入手
                </Button>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="c-section pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center pt-md-5">

              <div>
                <img className="my-5" src={iconCite} alt=""/>
                <p className="fs-lg mb-5 text-dark">
                  天候は私たちにとって危機管理、ならびに嵐への対応や回復の為に大変重要であり、エリアにおける<nobr>気象予報</nobr>の正確な情報を手に入れることで、対策の向上とコスト削減が可能になります。
                </p>
                <h6 className="h6 mb-5">
                  リサ・ランバート氏、Chief Technology and Innovation Officer、National Grid ならびに Senior Vice President、NGP
                </h6>
                <ImgLazy
                  className="rounded-circle"
                  src='https://cms2.climacell.co/wp-content/uploads/2019/02/lisa-lambert.jpg'
                  width={100}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
};